

export default {
	eeff: [
		{
			cliente: 2,
			balance_general: [
				{
					fecha: '2017',
					estado: 'Ajustado',
					activo_circulante: [
						{
							nombre: 'Caja y Bancos',
							importe: 13270,
							porcentaje: 4,  
						},
						{
							nombre: 'Inversiones temporales',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Clientes y doctos x cob',
							importe: 146522,
							porcentaje: 35,  
						},
						{
							nombre: 'Inventarios',
							importe: 144261,
							porcentaje: 35,  
						},
						{
							nombre: 'Cuentas por cobrar a filiales',
							importe: 21361,
							porcentaje: 5,  
						},
						{
							nombre: 'Pagos Anticipados',
							importe: 690,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros Act. Circ. Operativos',
							importe: 6579,
							porcentaje: 2,  
						},
						{
							nombre: 'Deudores diversos',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros Act. Circ. no operativos',
							importe: 0,
							porcentaje: 0,  
						},
					],
					activo_fijo: [
						{
							nombre: 'Propiedades planta y equipo net',
							importe: 7416,
							porcentaje: 2,  
						},
						{
							nombre: 'Inversion en subsidiarias/afiliado',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Ctas. por cob a filiales',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros activos fijos',
							importe: 1940,
							porcentaje: 0,  
						},
					],
					otros_activos: [
						{
							nombre: 'Activo por impuestos diferidos',
							importe: 72017,
							porcentaje: 17,  
						},
						{
							nombre: 'Patentes licencias y marcas',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Credito mercantil',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Cargos diferidos',
							importe: 0,
							porcentaje: 0,  
						},
					],
					pasivos_corto_plazo: [
						{
							nombre: 'Bancomer corto plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros bancos corto plazo',
							importe: 87978,
							porcentaje: 21,  
						},
						{
							nombre: 'Emision deuda corto plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Porc. Circ Largo plazo bancomer',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Porc. Circ Largo plazo otros bancos',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Proveedores',
							importe: 132567,
							porcentaje: 32,  
						},
						{
							nombre: 'Impuestos por pagar',
							importe: 10532,
							porcentaje: 3,  
						},
						{
							nombre: 'Cuentas por pagar filiales',
							importe: 24127,
							porcentaje: 6,  
						},
						{
							nombre: 'Gastos acumulados',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros pasivos cric. operativo',
							importe: 2007,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros pasivos cric. no operativo',
							importe: 0,
							porcentaje: 0,  
						},
					],
					pasivos_largo_plazo: [
						{
							nombre: 'Bancomer largo plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros banco largo plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Emisiones de deuda largo plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Ctas x pagar Filiales',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros pasivos largo plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Primas de entiguedad',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Pasivos diferidos',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Pasivos x impuestos diferidos',
							importe: 0,
							porcentaje: 0,  
						},
						
					],
					capital: [
						{
							nombre: 'Capital social actualizado',
							importe: 67362,
							porcentaje: 16,  
						},
						{
							nombre: 'Aportaciones pendientes capital',
							importe: 28135,
							porcentaje: 7,  
						},
						{
							nombre: 'Prima en emisiones de acciones',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Interes minoritario',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Exc(Insuf) actualización de capital',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Impacto ISR diferido',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Reservas',
							importe: 1312,
							porcentaje: 0,  
						},
						{
							nombre: 'Utilidad retenidas',
							importe: 27194,
							porcentaje: 7,  
						},
						{
							nombre: 'Itilidad del ejercicio',
							importe: 32842,
							porcentaje: 8,  
						},
					]
				},
				{
					fecha: '2018',
					estado: 'Ajustado',
					activo_circulante: [
						{
							nombre: 'Caja y Bancos',
							importe: 2512,
							porcentaje: 0,  
						},
						{
							nombre: 'Inversiones temporales',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Clientes y doctos x cob',
							importe: 216736,
							porcentaje: 36,  
						},
						{
							nombre: 'Inventarios',
							importe: 230166,
							porcentaje: 38,  
						},
						{
							nombre: 'Cuentas por cobrar a filiales',
							importe: 8048,
							porcentaje: 2,  
						},
						{
							nombre: 'Pagos Anticipados',
							importe: 1794,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros Act. Circ. Operativos',
							importe: 5602,
							porcentaje: 1,  
						},
						{
							nombre: 'Deudores diversos',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros Act. Circ. no operativos',
							importe: 0,
							porcentaje: 0,  
						},
					],
					activo_fijo: [
						{
							nombre: 'Propiedades planta y equipo net',
							importe: 7860,
							porcentaje: 2,  
						},
						{
							nombre: 'Inversion en subsidiarias/afiliado',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Ctas. por cob a filiales',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros activos fijos',
							importe: 2764,
							porcentaje: 0,  
						},
					],
					otros_activos: [
						{
							nombre: 'Activo por impuestos diferidos',
							importe: 126803,
							porcentaje: 21,  
						},
						{
							nombre: 'Patentes licencias y marcas',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Credito mercantil',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Cargos diferidos',
							importe: 0,
							porcentaje: 0,  
						},
					],
					pasivos_corto_plazo: [
						{
							nombre: 'Bancomer corto plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros bancos corto plazo',
							importe: 242494,
							porcentaje: 40,  
						},
						{
							nombre: 'Emision deuda corto plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Porc. Circ Largo plazo bancomer',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Porc. Circ Largo plazo otros bancos',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Proveedores',
							importe: 144879,
							porcentaje: 24,  
						},
						{
							nombre: 'Impuestos por pagar',
							importe: 23095,
							porcentaje: 4,  
						},
						{
							nombre: 'Cuentas por pagar filiales',
							importe: 9719,
							porcentaje: 2,  
						},
						{
							nombre: 'Gastos acumulados',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros pasivos cric. operativo',
							importe: 11045,
							porcentaje: 2,  
						},
						{
							nombre: 'Otros pasivos cric. no operativo',
							importe: 0,
							porcentaje: 0,  
						},
					],
					pasivos_largo_plazo: [
						{
							nombre: 'Bancomer largo plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros banco largo plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Emisiones de deuda largo plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Ctas x pagar Filiales',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros pasivos largo plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Primas de entiguedad',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Pasivos diferidos',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Pasivos x impuestos diferidos',
							importe: 0,
							porcentaje: 0,  
						},
						
					],
					capital: [
						{
							nombre: 'Capital social actualizado',
							importe: 85062,
							porcentaje: 14,  
						},
						{
							nombre: 'Aportaciones pendientes capital',
							importe: 28135,
							porcentaje: 7,  
						},
						{
							nombre: 'Prima en emisiones de acciones',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Interes minoritario',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Exc(Insuf) actualización de capital',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Impacto ISR diferido',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Reservas',
							importe: 2954,
							porcentaje: 0,  
						},
						{
							nombre: 'Utilidad retenidas',
							importe: 48594,
							porcentaje: 8,  
						},
						{
							nombre: 'Itilidad del ejercicio',
							importe: 34443,
							porcentaje: 6,  
						},
					]
				},
				{
					fecha: '2019',
					estado: 'Ajustado',
					activo_circulante: [
						{
							nombre: 'Caja y Bancos',
							importe: 9548,
							porcentaje: 1,  
						},
						{
							nombre: 'Inversiones temporales',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Clientes y doctos x cob',
							importe: 292743,
							porcentaje: 41,  
						},
						{
							nombre: 'Inventarios',
							importe: 251868,
							porcentaje: 35,  
						},
						{
							nombre: 'Cuentas por cobrar a filiales',
							importe: 10815,
							porcentaje: 2,  
						},
						{
							nombre: 'Pagos Anticipados',
							importe: 1497,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros Act. Circ. Operativos',
							importe: 7237,
							porcentaje: 1,  
						},
						{
							nombre: 'Deudores diversos',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros Act. Circ. no operativos',
							importe: 0,
							porcentaje: 0,  
						},
					],
					activo_fijo: [
						{
							nombre: 'Propiedades planta y equipo net',
							importe: 11245,
							porcentaje: 2,  
						},
						{
							nombre: 'Inversion en subsidiarias/afiliado',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Ctas. por cob a filiales',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros activos fijos',
							importe: 2124,
							porcentaje: 0,  
						},
					],
					otros_activos: [
						{
							nombre: 'Activo por impuestos diferidos',
							importe: 132507,
							porcentaje: 18,  
						},
						{
							nombre: 'Patentes licencias y marcas',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Credito mercantil',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Cargos diferidos',
							importe: 0,
							porcentaje: 0,  
						},
					],
					pasivos_corto_plazo: [
						{
							nombre: 'Bancomer corto plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros bancos corto plazo',
							importe: 281654,
							porcentaje: 39,  
						},
						{
							nombre: 'Emision deuda corto plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Porc. Circ Largo plazo bancomer',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Porc. Circ Largo plazo otros bancos',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Proveedores',
							importe: 176830,
							porcentaje: 25,  
						},
						{
							nombre: 'Impuestos por pagar',
							importe: 3514,
							porcentaje: 0,  
						},
						{
							nombre: 'Cuentas por pagar filiales',
							importe: 13005,
							porcentaje: 2,  
						},
						{
							nombre: 'Gastos acumulados',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros pasivos cric. operativo',
							importe: 967,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros pasivos cric. no operativo',
							importe: 0,
							porcentaje: 0,  
						},
					],
					pasivos_largo_plazo: [
						{
							nombre: 'Bancomer largo plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros banco largo plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Emisiones de deuda largo plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Ctas x pagar Filiales',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Otros pasivos largo plazo',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Primas de entiguedad',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Pasivos diferidos',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Pasivos x impuestos diferidos',
							importe: 0,
							porcentaje: 0,  
						},
						
					],
					capital: [
						{
							nombre: 'Capital social actualizado',
							importe: 85062,
							porcentaje: 12,  
						},
						{
							nombre: 'Aportaciones pendientes capital',
							importe: 28135,
							porcentaje: 7,  
						},
						{
							nombre: 'Prima en emisiones de acciones',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Interes minoritario',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Exc(Insuf) actualización de capital',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Impacto ISR diferido',
							importe: 0,
							porcentaje: 0,  
						},
						{
							nombre: 'Reservas',
							importe: 2954,
							porcentaje: 0,  
						},
						{
							nombre: 'Utilidad retenidas',
							importe: 71585,
							porcentaje: 10,  
						},
						{
							nombre: 'Itilidad del ejercicio',
							importe: 84013,
							porcentaje: 12,  
						},
					]
				},
			],
			estado_de_resultados: [
				{
					fecha: '2017',
					estado: 'Ajustado',
					datos: [
						{
							nombre: 'Ventas netas nacionales',
							simbolo: '+',
							importe: 930124,
							porcentaje: 0
						},
						{
							nombre: 'Ventas netas exportación',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Suma ventas netas',
							simbolo: '=',
							importe: 930124,
							porcentaje: 100
						},
						{
							nombre: 'Costo de ventas',
							simbolo: '-',
							importe: 745481,
							porcentaje: 80
						},
						{
							nombre: 'Utilidad Bruta',
							simbolo: '=',
							importe: 184643,
							porcentaje: 20
						},
						{
							nombre: 'Amortización',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Depreciacion',
							simbolo: '-',
							importe: 1731,
							porcentaje: 0
						},
						{
							nombre: 'Gastos de venta y administracion',
							simbolo: '-',
							importe: 122971,
							porcentaje: 13
						},
						{
							nombre: 'UAFIR',
							simbolo: '=',
							importe: 59941,
							porcentaje: 6
						},
						{
							nombre: 'Utilidad cambiaria',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad pos mon',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Perdida (utilidad) cambiaria',
							simbolo: '-',
							importe: 6903,
							porcentaje: 1
						},
						{
							nombre: 'Perdida (utilidad) pos mon',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Productos financieros',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Gastos financieros',
							simbolo: '-',
							importe: 8295,
							porcentaje: 1
						},
						{
							nombre: 'Costos integral de financiamiento',
							simbolo: '=',
							importe: 44743,
							porcentaje: 5
						},
						{
							nombre: 'Otros Gastos',
							simbolo: '-',
							importe: 628,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad antes de ISR y PTU',
							simbolo: '=',
							importe: 45371,
							porcentaje: 5
						},
						{
							nombre: 'Otros ingresos',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad antes de impuestos a la Utilidad',
							simbolo: '=',
							importe: 45371,
							porcentaje: 5
						},
						{
							nombre: 'Impuestos y PUT diferido',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Impac causado',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'ISR Causado',
							simbolo: '-',
							importe: 12529,
							porcentaje: 1
						},
						{
							nombre: 'Utilidad antes de partida extraordinaria',
							simbolo: '=',
							importe: 32842,
							porcentaje: 4
						},
						{
							nombre: 'Perdida Sub/Afils',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad SUB/AFILS',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad (perdida) neta',
							simbolo: '=',
							importe: 32842,
							porcentaje: 4
						},
						{
							nombre: 'UAFIRDA',
							simbolo: '=',
							importe: 61672,
							porcentaje: 7
						},
					]
				},
				{
					fecha: '2018',
					estado: 'Ajustado',
					datos: [
						{
							nombre: 'Ventas netas nacionales',
							simbolo: '+',
							importe: 1257007,
							porcentaje: 0
						},
						{
							nombre: 'Ventas netas exportación',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Suma ventas netas',
							simbolo: '=',
							importe: 1257007,
							porcentaje: 100
						},
						{
							nombre: 'Costo de ventas',
							simbolo: '-',
							importe: 1018105,
							porcentaje: 81
						},
						{
							nombre: 'Utilidad Bruta',
							simbolo: '=',
							importe: 238902,
							porcentaje: 19
						},
						{
							nombre: 'Amortización',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Depreciacion',
							simbolo: '-',
							importe: 1777,
							porcentaje: 0
						},
						{
							nombre: 'Gastos de venta y administracion',
							simbolo: '-',
							importe: 152597,
							porcentaje: 12
						},
						{
							nombre: 'UAFIR',
							simbolo: '=',
							importe: 84528,
							porcentaje: 7
						},
						{
							nombre: 'Utilidad cambiaria',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad pos mon',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Perdida (utilidad) cambiaria',
							simbolo: '-',
							importe: 15127,
							porcentaje: 1
						},
						{
							nombre: 'Perdida (utilidad) pos mon',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Productos financieros',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Gastos financieros',
							simbolo: '-',
							importe: 14730,
							porcentaje: 1
						},
						{
							nombre: 'Costos integral de financiamiento',
							simbolo: '=',
							importe: 54671,
							porcentaje: 4
						},
						{
							nombre: 'Otros Gastos',
							simbolo: '-',
							importe: 2454,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad antes de ISR y PTU',
							simbolo: '=',
							importe: 57125,
							porcentaje: 5
						},
						{
							nombre: 'Otros ingresos',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad antes de impuestos a la Utilidad',
							simbolo: '=',
							importe: 57125,
							porcentaje: 5
						},
						{
							nombre: 'Impuestos y PUT diferido',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Impac causado',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'ISR Causado',
							simbolo: '-',
							importe: 22682,
							porcentaje: 1
						},
						{
							nombre: 'Utilidad antes de partida extraordinaria',
							simbolo: '=',
							importe: 34443,
							porcentaje: 3
						},
						{
							nombre: 'Perdida Sub/Afils',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad SUB/AFILS',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad (perdida) neta',
							simbolo: '=',
							importe: 34443,
							porcentaje: 3
						},
						{
							nombre: 'UAFIRDA',
							simbolo: '=',
							importe: 86305,
							porcentaje: 7
						},
					]
				},
				{
					fecha: '2019',
					estado: 'Ajustado',
					datos: [
						{
							nombre: 'Ventas netas nacionales',
							simbolo: '+',
							importe: 1728144,
							porcentaje: 0
						},
						{
							nombre: 'Ventas netas exportación',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Suma ventas netas',
							simbolo: '=',
							importe: 1728144,
							porcentaje: 100
						},
						{
							nombre: 'Costo de ventas',
							simbolo: '-',
							importe: 1377182,
							porcentaje: 80
						},
						{
							nombre: 'Utilidad Bruta',
							simbolo: '=',
							importe: 350962,
							porcentaje: 20
						},
						{
							nombre: 'Amortización',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Depreciacion',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Gastos de venta y administracion',
							simbolo: '-',
							importe: 209460,
							porcentaje: 12
						},
						{
							nombre: 'UAFIR',
							simbolo: '=',
							importe: 141502,
							porcentaje: 8
						},
						{
							nombre: 'Utilidad cambiaria',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad pos mon',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Perdida (utilidad) cambiaria',
							simbolo: '-',
							importe: 12314,
							porcentaje: 1
						},
						{
							nombre: 'Perdida (utilidad) pos mon',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Productos financieros',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Gastos financieros',
							simbolo: '-',
							importe: 18842,
							porcentaje: 1
						},
						{
							nombre: 'Costos integral de financiamiento',
							simbolo: '=',
							importe: 110346,
							porcentaje: 6
						},
						{
							nombre: 'Otros Gastos',
							simbolo: '-',
							importe: 5192,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad antes de ISR y PTU',
							simbolo: '=',
							importe: 115538,
							porcentaje: 7
						},
						{
							nombre: 'Otros ingresos',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad antes de impuestos a la Utilidad',
							simbolo: '=',
							importe: 115538,
							porcentaje: 7
						},
						{
							nombre: 'Impuestos y PUT diferido',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Impac causado',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'ISR Causado',
							simbolo: '-',
							importe: 31525,
							porcentaje: 2
						},
						{
							nombre: 'Utilidad antes de partida extraordinaria',
							simbolo: '=',
							importe: 84013,
							porcentaje: 5
						},
						{
							nombre: 'Perdida Sub/Afils',
							simbolo: '-',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad SUB/AFILS',
							simbolo: '+',
							importe: 0,
							porcentaje: 0
						},
						{
							nombre: 'Utilidad (perdida) neta',
							simbolo: '=',
							importe: 84013,
							porcentaje: 5
						},
						{
							nombre: 'UAFIRDA',
							simbolo: '=',
							importe: 141502,
							porcentaje: 8
						},
					]
				},
			],
			flujo_de_caja: [
				{
					fecha: '2017',
					estado: 'No ajustado',
					datos: [
						{
							nombre: 'Generación Interna de Fondos',
							importe: 0,
						},
						{
							nombre: 'Variación en Capital de Trabajo',
							importe: 0,
						},
						{
							nombre: 'Flujo de Efectivo Operacional',
							importe: 0,
						},
						{
							nombre: 'Inversión en Bienes de Capital',
							importe: 0,
						},
						{
							nombre: 'Inversiones / Desinversiones',
							importe: 0,
						},
						{
							nombre: 'Flujo de Efectivo de las Inversiones',
							importe: 0,
						},
						{
							nombre: 'Variación Neta Deuda Financiera',
							importe: 0,
						},
						{
							nombre: 'Incrementos de Capital',
							importe: 0,
						},
						{
							nombre: 'Dividendos Recompras y Acciones',
							importe: 0,
						},
						{
							nombre: 'Flujo de Efectivo de los Financiamientos',
							importe: 0,
						},
					]
				},
				{
					fecha: '2018',
					estado: 'No ajustado',
					datos: [
						{
							nombre: 'Generación Interna de Fondos',
							importe: 0,
						},
						{
							nombre: 'Variación en Capital de Trabajo',
							importe: 0,
						},
						{
							nombre: 'Flujo de Efectivo Operacional',
							importe: 0,
						},
						{
							nombre: 'Inversión en Bienes de Capital',
							importe: 0,
						},
						{
							nombre: 'Inversiones / Desinversiones',
							importe: 0,
						},
						{
							nombre: 'Flujo de Efectivo de las Inversiones',
							importe: 0,
						},
						{
							nombre: 'Variación Neta Deuda Financiera',
							importe: 0,
						},
						{
							nombre: 'Incrementos de Capital',
							importe: 0,
						},
						{
							nombre: 'Dividendos Recompras y Acciones',
							importe: 0,
						},
						{
							nombre: 'Flujo de Efectivo de los Financiamientos',
							importe: 0,
						},
					]
				},
				{
					fecha: '2019',
					estado: 'No ajustado',
					datos: [
						{
							nombre: 'Generación Interna de Fondos',
							importe: 84013,
						},
						{
							nombre: 'Variación en Capital de Trabajo',
							importe: 0,
						},
						{
							nombre: 'Flujo de Efectivo Operacional',
							importe: 7358,
						},
						{
							nombre: 'Inversión en Bienes de Capital',
							importe: 0,
						},
						{
							nombre: 'Inversiones / Desinversiones',
							importe: 14197,
						},
						{
							nombre: 'Flujo de Efectivo de las Inversiones',
							importe: -32124,
						},
						{
							nombre: 'Variación Neta Deuda Financiera',
							importe: 4112,
						},
						{
							nombre: 'Incrementos de Capital',
							importe: 0,
						},
						{
							nombre: 'Dividendos Recompras y Acciones',
							importe: -11452,
						},
						{
							nombre: 'Flujo de Efectivo de los Financiamientos',
							importe: 39160,
						},
					]
				},
			],
			ratios_y_magnitudes: [
				{
					fecha: '2017',
					estado: 'No ajustado',
					datos: [
						{
							nombre: 'Capital de Trabajo',
							importe: '$83,508.00',
						},
						{
							nombre: 'Deuda Financiera Total',
							importe: '$87,978.00',
						},
						{
							nombre: 'Deuda Financiera Neta',
							importe: '$87,978.00',
						},
						{
							nombre: 'Payback',
							importe: '4',
						},
						{
							nombre: 'Cobertura de Intereses',
							importe: '7.23',
						},
						{
							nombre: 'Flujo de Efectivo Discrecional',
							importe: '$0.00',
						},
						{
							nombre: 'Deuda Fin Tot / Capital Contable',
							importe: '1.63',
						},
						{
							nombre: 'Deuda Financiera Total / UAFIRDA',
							importe: '1.42',
						},
						{
							nombre: 'Deuda Financiera Neta / UAFIRDA',
							importe: '1.42',
						},
						{
							nombre: 'Rotación Clientes',
							importe: '6.3',
						},
						{
							nombre: 'Rotación Proveedores',
							importe: '5.62',
						},
						{
							nombre: 'Rotación Inventarios',
							importe: '5.16',
						},
					]
				},
				{
					fecha: '2018',
					estado: 'No ajustado',
					datos: [
						{
							nombre: 'Capital de Trabajo',
							importe: '$62,041.00',
						},
						{
							nombre: 'Deuda Financiera Total',
							importe: '$242,494.00',
						},
						{
							nombre: 'Deuda Financiera Neta',
							importe: '$242,494.00',
						},
						{
							nombre: 'Payback',
							importe: '5',
						},
						{
							nombre: 'Cobertura de Intereses',
							importe: '5.74',
						},
						{
							nombre: 'Flujo de Efectivo Discrecional',
							importe: '$0.00',
						},
						{
							nombre: 'Deuda Fin Tot / Capital Contable',
							importe: '2.52',
						},
						{
							nombre: 'Deuda Financiera Total / UAFIRDA',
							importe: '2.8',
						},
						{
							nombre: 'Deuda Financiera Neta / UAFIRDA',
							importe: '2.8',
						},
						{
							nombre: 'Rotación Clientes',
							importe: '5.7',
						},
						{
							nombre: 'Rotación Proveedores',
							importe: '7.02',
						},
						{
							nombre: 'Rotación Inventarios',
							importe: '4.42',
						},
					]
				},
				{
					fecha: '2019',
					estado: 'No ajustado',
					datos: [
						{
							nombre: 'Capital de Trabajo',
							importe: '$95,675.00',
						},
						{
							nombre: 'Deuda Financiera Total',
							importe: '$281,654.00',
						},
						{
							nombre: 'Deuda Financiera Neta',
							importe: '$281,654.0',
						},
						{
							nombre: 'Payback',
							importe: '3',
						},
						{
							nombre: 'Cobertura de Intereses',
							importe: '7.51',
						},
						{
							nombre: 'Flujo de Efectivo Discrecional',
							importe: '$7,036.00',
						},
						{
							nombre: 'Deuda Fin Tot / Capital Contable',
							importe: '1.95',
						},
						{
							nombre: 'Deuda Financiera Total / UAFIRDA',
							importe: '1.99',
						},
						{
							nombre: 'Deuda Financiera Neta / UAFIRDA',
							importe: '1.99',
						},
						{
							nombre: 'Rotación Clientes',
							importe: '5.9',
						},
						{
							nombre: 'Rotación Proveedores',
							importe: '7.78',
						},
						{
							nombre: 'Rotación Inventarios',
							importe: '5.46',
						},
					]
				},
			]
		},

		{
			cliente: 4,
			balance_general: [
				{
					fecha: '2015',
					estado: 'CONSOLIDATED',
					activo_circulante: [
						{
							nombre: 'ACTIVO NO CORRIENTE',
							importe: 158324,
							porcentaje: '',  
						},
						{
							nombre: 'Inmovilizado Inagible',
							importe: 220,
							porcentaje: '',  
						},
						{
							nombre: 'Inmovilizado Material',
							importe: 158104,
							porcentaje: '',  
						},
						{
							nombre: 'Inveersiones en empresas',
							importe: 0,
							porcentaje: '',  
						},
						{
							nombre: 'Inveersiones financieras LP',
							importe: 0,
							porcentaje: '',  
						},
						{
							nombre: 'Fondo de Comercio',
							importe: 0,
							porcentaje: '',  
						},
						{
							nombre: 'Impuestos diferidos',
							importe: 0,
							porcentaje: '',  
						},
					],
					activo_fijo: [
						{
							nombre: 'ACTIVO CORRIENTE',
							importe: 1359115,
							porcentaje: 90,  
						},
						{
							nombre: 'Existencias',
							importe: 12671,
							porcentaje: null,  
						},
						{
							nombre: 'Clientes',
							importe: 594874,
							porcentaje: null,  
						},
						{
							nombre: 'Empresas Grupo',
							importe: 19156,
							porcentaje: null,  
						},
						{
							nombre: 'Otros deudores',
							importe: 39247,
							porcentaje: null,  
						},
						{
							nombre: 'Inversiones Financieras CP',
							importe: 273450,
							porcentaje: null,  
						},
						{
							nombre: 'Otros activos corrientes',
							importe: 85500,
							porcentaje: null,  
						},
						{
							nombre: 'Efectivo y equivalentes de efectivo',
							importe: 334217,
							porcentaje: null,  
						},
						{
							nombre: 'TOTAL ACTIVO',
							importe: 1517439,
							porcentaje: 100,  
						},
					],
					otros_activos: [],
					pasivos_corto_plazo: [],
					pasivos_largo_plazo: [],
					capital: [
						{
							nombre: 'PATRIMONIO NETO',
							importe: 687769,
							porcentaje: 45,  
						},
						{
							nombre: 'Capital',
							importe: 254650,
							porcentaje: null,  
						},
						{
							nombre: 'Reservas y otros FFPP',
							importe: 265969,
							porcentaje: null,  
						},
						{
							nombre: 'Resultados',
							importe: 167150,
							porcentaje: null,  
						},
						{
							nombre: 'Intereses minoritarios',
							importe: 0,
							porcentaje: null,  
						},
						{
							nombre: 'PASIVOS NO CORRIENTES',
							importe: 0,
							porcentaje: null,  
						},
						{
							nombre: 'Provis. LP e Ingr Difereidos',
							importe: 0,
							porcentaje: null,  
						},
						{
							nombre: 'Bancos + Bonos LP',
							importe: 0,
							porcentaje: null,  
						},
						{
							nombre: 'Grupo l/p',
							importe: 0,
							porcentaje: null,  
						},
						{
							nombre: 'Otros pasivos no corrientes',
							importe: 0,
							porcentaje: null,  
						},
						{
							nombre: 'PASIVOS CORRIENTES',
							importe: 829670,
							porcentaje: 55,  
						},
						{
							nombre: 'Bancos + Bonos CP',
							importe: 280767,
							porcentaje: null,  
						},
						{
							nombre: 'Grupo c/p',
							importe: 34805,
							porcentaje: null,  
						},
						{
							nombre: 'Proveedores',
							importe: 411169,
							porcentaje: null,  
						},
						{
							nombre: 'Otros CP',
							importe: 102929,
							porcentaje: null,  
						},
						{
							nombre: 'TOTAL P. NETO Y PASIVO',
							importe: 1517439,
							porcentaje: 100,  
						},
					],
				}
			]
		}
	],
	rating: [
		{
			cliente: 2,
			total: 76,
			escalaMaestra: 'BBB-1',
			totales: {
				cuantitativoR: 26.91,
				buroR: 24.26,
				clienteR: 24.83,
			},
			cuantitativoR: [
				{
					campo: 'Capital Contable / Activo Fijo',
					valor: '18.22230533',
					puntaje: '12.49',
					max: '1.1'
				},
				{
					campo: 'Crecimiento Real en Ventas',
					valor: '37.48%',
					puntaje: '0',
					max: '6.87'
				},
				{
					campo: 'Deuda Financiera / Pasivo Total',
					valor: '0.591747379',
					puntaje: '1',
					max: '6.15'
				},
				{
					campo: 'Rotación de Inventarios',
					valor: '5.46787206',
					puntaje: '4.56',
					max: '3.29'
				},
				{
					campo: 'Margen Operativo',
					valor: '141502',
					puntaje: '0.97',
					max: '1.46'
				},
				{
					campo: 'Pasivo Total / Capital Contable',
					valor: '2',
					puntaje: '7.15',
					max: '1.47'
				},
				{
					campo: 'Pay Back Administración',
					valor: '3',
					puntaje: '0',
					max: '3.69'
				},
				{
					campo: 'Prueba Ácido (Caja Bancos)',
					valor: '0.67617707',
					puntaje: '0',
					max: '0.91'
				},
				{
					campo: 'UAFIRDA / Gastos Financieros',
					valor: '7.509924636',
					puntaje: '0.74',
					max: '2.48'
				},
				{
					campo: 'Ventas Netas / Activo Total',
					valor: '2.401587584',
					puntaje: '0',
					max: '1.46'
				},
			],
			buroR: [
				{
					campo: 'Decremento en Mora de los últimos 24 meses',
					valor: '',
					puntaje: '3.34',
					max: '3.82'
				},
				{
					campo: 'Deuda Buró / Ingresos Mínimos de los últimos 3 meses',
					valor: '',
					puntaje: '5.54',
					max: '2.73'
				},
				{
					campo: 'Decremento en Mora  de los últimos 18 meses de cuentas abiertas exclusivamente',
					valor: '',
					puntaje: '0.97',
					max: '9.25'
				},
				{
					campo: 'Saldo Medio en productos de Pasivo vista del ultimo mes / Deuda Financiera',
					valor: '',
					puntaje: '3.31',
					max: '3.82'
				},
				{
					campo: 'Tasa de Créditos Vigentes MOP 1 abiertos',
					valor: '',
					puntaje: '3.52',
					max: '4.21'
				},
				{
					campo: 'Tasa de Incidencia de los últimos 12 Meses',
					valor: '',
					puntaje: '2.35',
					max: '4.66'
				},
				{
					campo: 'Máximo de los saldos medios en productos de Pasivo no vista de los últimos  3 meses / Exposición actual total en BBVA',
					valor: '',
					puntaje: '5.23',
					max: '3.98'
				},
			],
			clienteR: [
				{
					campo: 'Antigüedad del Cliente con BBVA',
					valor: '',
					puntaje: '6',
					max: '4.15'
				},
				{
					campo: 'Certificación y procedencia de la auditoria realizada a la empresa',
					valor: '',
					puntaje: '3.2',
					max: '1.78'
				},
				{
					campo: 'Rating Sectorial',
					valor: '',
					puntaje: '4.97',
					max: '1.82'
				},
				{
					campo: 'Empresas con refinanciamientos y reestructuraciones',
					valor: '',
					puntaje: '7.26',
					max: '7.09'
				},
				{
					campo: 'Ventas Netas de la Empresa / Ventas Netas de su Grupo Empresarial',
					valor: '',
					puntaje: '3.4',
					max: '5.16'
				},
			]
		}

	]
}