import arce from '../../../services/arce'
export const saveArce = async ({commit}, payload) => {
	try{
		let data = await arce.save(payload)
	} catch (err) {

	}
}

export const getArce = async ({commit}, payload) => {
	try {
		let data = await arce.findOne({_id: payload})
		commit('setArce', data)
	} catch (error) {
		
	}
}

export const updateArce = async ({commit}, payload) => {
	try {
		let data = await arce.update(payload)
		commit('setArce', payload)
	} catch (err) {
	}
}
