import { saveUserEvaluation } from '../store/auth/actions'

const Datastore = require('nedb-promises')

let db = new Datastore({
	filename: 'wizardarce.db', // provide a path to the database file 
	autoload: true, // automatically load the database
	timestampData: true // automatically add and manage the fields createdAt and updatedAt
})
export default {
	// async getAll(payload) {
	// 	return await db.find({
	// 		userId: payload,
	// 	},{})
	// },
	
	async findOne(payload) {
		return await db.findOne(payload,{})
	},
	
	

	async save(payload) {
		let exist = await db.findOne({userId: payload.userId, 'arce.client.id': payload.arce.client.id}, {})
		if(!exist) {
			return await db.insert(Object.assign({}, payload))
		}
		let updated = await db.update({ userId: payload.userId, 'arce.client.id': payload.arce.client.id }, payload, {returnUpdatedDocs: true})
		
		return updated
	},

	// async saveEvaluation(payload) {
	// 	let exist = await this.findOne({userId: payload.userId, activityId: payload.activityId})
	// }
}