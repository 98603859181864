import Vue from 'vue'
import VueRouter from 'vue-router'
import middleware from '../middleware'


Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'arce',
	component: () => import(/* webpackChunkName: "about" */ '../views/SimulatorTeach.vue'),
	
	children: [
	

		{
			name: 'ArceHome',
			path: '/:help?',
			component: () => import('../views/Arce/ArceHome.vue'),
		},
		{
			name: 'ArceList',
			path: 'arce/list',
			component: () => import('../views/Arce/ArceList.vue'),
		},
		{
			name: 'ArceRating',
			path: 'arce/rating/:id',
			component: () => import('../views/Arce/ArceRating.vue'),
		},

		{
			name: 'arce-clients',
			path: 'clients',
			// component: () => import('../views/SimulatorTeach/ArceClients.vue'),
			component: () => import('../views/SimulatorTeach/ArceHome.vue'),
		},
		{
			name: 'arce-client',
			path: 'clients/:id',
			component: () => import('../views/SimulatorTeach/ArceClient.vue'),
		},
		
		{
			name: 'arce-profile-client',
			path: 'clients/profile/:id',
			component: () => import('../views/Arce/ArceProfileClient.vue'),
		},
		{
			name: 'arce-policies-resumen',
			path: 'policies/resumen/:id',
			component: () => import('../views/SimulatorTeach/ArcePoliciesResumen.vue'),
		},
		{
			name: 'arce-details',
			path: 'details/:id',
			component: () => import('../views/SimulatorTeach/ArceDetails.vue'),
		},
		{
			name: 'arce-index',
			path: ':help?',
			component: () => import('../views/SimulatorTeach/ArceHome.vue'),
		},
		{
			name: 'arce-error',
			path: '*',
			redirect: { name: 'formation-index' }
		}
	]
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	if (to.meta.middleware) {
		const nameMiddleware = Array.isArray(to.meta.middleware)
			? to.meta.middleware
			: [to.meta.middleware]

		const context = {
			from,
			next,
			router,
			to,
		};
		const nextMiddleware = middleware[nextFactory(context, nameMiddleware, 1)] || next
		return middleware[nameMiddleware[0]]({ ...context, next: nextMiddleware })
	}

	return next();
});

function nextFactory(context, middleware, index) {
	const subsequentMiddleware = middleware[index];
	if (!subsequentMiddleware) return context.next;
	return (...parameters) => {
	  context.next(...parameters)
	  const nextMiddleware = nextFactory(context, middleware, index + 1)
	  subsequentMiddleware({ ...context, next: nextMiddleware })
	};
}

export default router
