let localRatings = localStorage.getItem('ratings')
if(localRatings) {
	localRatings = JSON.parse(localRatings)
} else{
	localRatings = [{
		id: 1,
		fecha: '',
		bbvaid: '123456',
		numero: '0000012512',
		nombre: 'PROPYSOL, SA DE CV',
		alpha_code: 'MX0074022007739',
		ric_code: 'TTU97007DM2',
		folio: '0000012512',
		tipo: 'Grupo',
		padre: 0,
		propietario: 'Natividad Hergueta',
		rfc: 'HLQ090319YUW',
		fundacion: '2002',
		clinte_desde: '2010',
		pais_cabecera: 'Mexico',
		examen: false,
		status: 'prepair',
		analysis: {
			basic: {
				form: {
					// 
					group: 'GURPO TUM',
					parent_company: 'TUM TRANSPORTISTAS UNIDOS MEXICANOS DIVISION NORTE SA DE CV',
					parent_tax_id: '',
					core_asset_bbva: 'Transportation',
					// Management
					// STRATEGIC POSITIONING
					existence_clarity_focus_track_record: 7.5,
					// RISK MANAGEMENT / FINANCIAL MANAGEMENT
					style_and_tolereance_for_risk: 7.5,
					operational_controls: 7.5,
					// ORGANIZATIONAL EFFECTIVENESS
					quanlity_and_depth: 8,
					continuity: 8,
					success_viability_flexibility: 8,
					// GOVERNANCE
					executive_board_over_sight: 9,
					// MANAGEMENT ASSESSMENT
					management_valuation: 'Weak',
					management_assessment: '...'
				}
			},
			business_risk: {
				form: {
					business_risk_assessment: '',
					subactivities: [
						{
							id: 1,
							sector_bbva: 'Transportation',
							sub_sector_bbva: 'Logistic services',
							activity_bbva: 'Logistic services',
							sub_activity_bbva: 'Logistic services',
							template: 'Generic',
							country: 'Mexico',
							sales: 100,
							industry_risk_score: 5.5,
							final_industry_risk_score: 6.5,
							indutry_risk_valuation: 'Intermediate risk',
							industry_risk_comments: 'Riesgo intermedio',
							// 	COMPETITIVE ADVENTAGE
							market_position: 7.5,
							// SCALE, SCOPE AND DIVERSITITY
							diversification: 7.5,
							// OPERATING EFFICIENCY
							operating_efficiency: 7.5,
							// COMPETITIVE ASSESSMENT
							competitive_valuation: 'Week',
							competitive_assessment: '...'
						}
					],
					
				},
			},
			financial_risk: {
				form: {
					ffss: [
						{
							ffss_id: '00000000000000000543895',
							ffss_close_by: 'E023754',
							ffss_confirmed_by: 'E023754',
							ffss_date: '2022-06-30',
							original_currency: 'MXN',
							consolotation_type: 'Individual',
							accounting_adjustment: 'No',
							ffss_cetification_type: 'FISCAL',
							audit_opinion: '',
							auditor_name: 'INTERNO',
							period: '6M',
							commnets_on_certification_type_consolidation_perimenter_accounting_adjustment: 'AJUSTE LEASING',
							balance: {
								assets: {
									non_current_assets: 1902961000, 
									property_plant_equipment: 1344295000, 
									intangible_assets: 0, 
									investments: 0, 
									financial_assets_lt: 558666000, 
									goodwill: 0, 
									deferrend_tax_assets: 0, 
									current_assets: 890912000, 
									inventories: 7037000, 
									trade_accounts_receivable: 7037000, 
									related_companies: 37534000, 
									other_debtors: 509402000, 
									other_current_assets: 4809000, 
									st_investment: 0, 
									cash_and_cash_equivalents: 7706000, 
									total_assets: 2793873000, 
								},
								liabilities: {
									net_worth: 501549000,
									common_stock: 96837000,
									reserves_and_other_equity: 404712000,
									minority_interests: 0,
									non_current_liabilities: 964749000,
									provisions_and_deferred_income_lt: 380721000,
									bank_bonds_derivates_leases_lt: 584028000,
									bank_bonds_derivates_leases_st: 585983000,
									related_companies_st: 172279000,
									trade_accounts_payable: 478030000,
									other_st: 91283000,
									total_equity_liabilities: 2793873000,
								}
							},
							income: {
								total_revenues: 99889000,
								ebitda: 63765000,
								net_operating_income_ebit: -32676000,
								interest_expense: 46781000,
								equity_accounted_investment_results: 0,
								net_financial_results: -48305000,
								income_cont_operations: -80981000,
								net_other_results: 0,
								income_before_taxe_discn_oper: -80981000,
								income_taxes: 0,
								net_discontinued_operations_result: 0,
								net_income_after_taxes_disc_oper: -80981000,
								net_income_atributable: -80981000,
							},
							cash_flow: {
								funds_form_operations: 0,
								change_in_working_capital: 0,
								dividends_received_oper: 0,
								cash_flow_operating_act_cfo: 0,
								capex: 0,
								investments_divestments: 0,
								dividends_received_invest: 0,
								other_cash_flow_inesting_act: 0,
								cash_flow_investing_act_cfi: 0,
								net_changes_financial_debt: 0,
								capital_increase: 0,
								dividends: 0,
								share_buyback: 0,
								other_cash_flow_financing_act: 0,
								cash_flow_financing_act_cff: 0,
								total_movements_in_cash: 0,
							},
							solvency: {
								gross_financial_debt_to_ebitad_adj_x: 9.17,
								net_financial_debt_to_ebitad_adj_x: 9.11,
								ebitad_adj_interest_expenses: 1.36,
								gif_to_gross_financial_debt: 19,
								gif_to_net_financial_debt: 20,
								ebitad_adj_fixed_charge: 0.19,
							},
							liquidity: {
								net_worth_total_asset: 18,
								long_term_debt_net_worth: 116,
								c_asset_c_liabilities: 0.67,
								fixed_asset_net_worth: 379,
								gross_financial_debt_to_net_worth: 2.33,
							},
							profitability: {
								ebitda_adj_margin: 6,
								roa: -6,
								roe: 32,
								roce: -4,
							},
							activity: {
								avg_days_sale_out: 59.39,
								avg_days_inventory_out: 1.64,
								avg_days_payable_out: 111.07,
								avg_period_to_maturity_days: 50.05,
							}
						}
					],
					// Capital Structure
					debt_maturity_profile: 'Negative',
					market_risk: '7.5',
					counter_party_credit_bank: '7.5',
					capital_structure_assessment: '...',
					// Off-Balance Sheet
					off_balance_sheet_risk: 8,
					off_balance_sheet_assessment: 'El cliente no registra en sus estados financieros la deuda por arrendamiento puro',
					// Financial Flexibility and Liquidity
					access_to_additional_founding_or_capital: 'Average',
					need_for_additional_founding_or_capital: 'Very High',
					financial_flexibility_liquidity_score: '8.5',
					financial_flexibility_liquidity_score_assessment: '...',
					undrawn_debt: 'NO',
					
				}
			},
			standalone_rating: {
				form: {
					// Standalone Rating
					scale: 'Long Scale BBVA',
					long_rating_value: '8.3',
					total_rating_score: '8.75',
					ffss_year: '2022-06-30',
				}
			},
			overlays: {
				form: {
					// General
					adjustment_gre: 'NO',
					wl_level: '2',
					// WL Overlay
					// Input
					icr_rating: '8-3',
					icr_rating_score: 8.75,
					input_wl_level: 2,
					icr_rating_adjustment: '',
					icr_rating_comments: '',
					// Output
					overlay_rating: '8-3',
					overlay_rating_score: '8.75',
				}
			}
		}
	},
	{
		id: 2,
		fecha: '',
		bbvaid: '987456',
		numero: '000001352',
		nombre: 'Soriano Group, SA DE CV',
		folio: '000001352',
		tipo: 'Grupo',
		padre: 0,
		propietario: 'Soriano',
		rfc: 'HLQ090319YUW',
		fundacion: '2002',
		clinte_desde: '2010',
		pais_cabecera: 'Mexico',
		examen: false,
		rating: {

		}
	},]
}

export default {
	selected: null,
	ratings: localRatings,
	ratings2: [
		{
			id: 1,
			fecha: '',
			bbvaid: '123456',
			numero: '0000012512',
			nombre: 'PROPYSOL, SA DE CV',
			alpha_code: 'MX0074022007739',
			ric_code: 'TTU97007DM2',
			folio: '0000012512',
			tipo: 'Grupo',
			padre: 0,
			propietario: 'Natividad Hergueta',
			rfc: 'HLQ090319YUW',
			fundacion: '2002',
			clinte_desde: '2010',
			pais_cabecera: 'Mexico',
			examen: false,
			status: 'prepair',
			analysis: {
				basic: {
					form: {
						// 
						group: 'GURPO TUM',
						parent_company: 'TUM TRANSPORTISTAS UNIDOS MEXICANOS DIVISION NORTE SA DE CV',
						parent_tax_id: '',
						core_asset_bbva: 'Transportation',
						// Management
						// STRATEGIC POSITIONING
						existence_clarity_focus_track_record: 7.5,
						// RISK MANAGEMENT / FINANCIAL MANAGEMENT
						style_and_tolereance_for_risk: 7.5,
						operational_controls: 7.5,
						// ORGANIZATIONAL EFFECTIVENESS
						quanlity_and_depth: 8,
						continuity: 8,
						success_viability_flexibility: 8,
						// GOVERNANCE
						executive_board_over_sight: 9,
						// MANAGEMENT ASSESSMENT
						management_valuation: 'Weak',
						management_assessment: '...'
					}
				},
				business_risk: {
					form: {
						business_risk_assessment: '',
						subactivities: [
							{
								id: 1,
								sector_bbva: 'Transportation',
								sub_sector_bbva: 'Logistic services',
								activity_bbva: 'Logistic services',
								sub_activity_bbva: 'Logistic services',
								template: 'Generic',
								country: 'Mexico',
								sales: 100,
								industry_risk_score: 5.5,
								final_industry_risk_score: 6.5,
								indutry_risk_valuation: 'Intermediate risk',
								industry_risk_comments: 'Riesgo intermedio',
								// 	COMPETITIVE ADVENTAGE
								market_position: 7.5,
								// SCALE, SCOPE AND DIVERSITITY
								diversification: 7.5,
								// OPERATING EFFICIENCY
								operating_efficiency: 7.5,
								// COMPETITIVE ASSESSMENT
								competitive_valuation: 'Week',
								competitive_assessment: '...'
							}
						],
						
					},
				},
				financial_risk: {
					form: {
						ffss: [
							{
								ffss_id: '00000000000000000543895',
								ffss_close_by: 'E023754',
								ffss_confirmed_by: 'E023754',
								ffss_date: '2022-06-30',
								original_currency: 'MXN',
								consolotation_type: 'Individual',
								accounting_adjustment: 'No',
								ffss_cetification_type: 'FISCAL',
								audit_opinion: '',
								auditor_name: 'INTERNO',
								period: '6M',
								commnets_on_certification_type_consolidation_perimenter_accounting_adjustment: 'AJUSTE LEASING',
								balance: {
									assets: {
										non_current_assets: 1902961000, 
										property_plant_equipment: 1344295000, 
										intangible_assets: 0, 
										investments: 0, 
										financial_assets_lt: 558666000, 
										goodwill: 0, 
										deferrend_tax_assets: 0, 
										current_assets: 890912000, 
										inventories: 7037000, 
										trade_accounts_receivable: 7037000, 
										related_companies: 37534000, 
										other_debtors: 509402000, 
										other_current_assets: 4809000, 
										st_investment: 0, 
										cash_and_cash_equivalents: 7706000, 
										total_assets: 2793873000, 
									},
									liabilities: {
										net_worth: 501549000,
										common_stock: 96837000,
										reserves_and_other_equity: 404712000,
										minority_interests: 0,
										non_current_liabilities: 964749000,
										provisions_and_deferred_income_lt: 380721000,
										bank_bonds_derivates_leases_lt: 584028000,
										bank_bonds_derivates_leases_st: 585983000,
										related_companies_st: 172279000,
										trade_accounts_payable: 478030000,
										other_st: 91283000,
										total_equity_liabilities: 2793873000,
									}
								},
								income: {
									total_revenues: 99889000,
									ebitda: 63765000,
									net_operating_income_ebit: -32676000,
									interest_expense: 46781000,
									equity_accounted_investment_results: 0,
									net_financial_results: -48305000,
									income_cont_operations: -80981000,
									net_other_results: 0,
									income_before_taxe_discn_oper: -80981000,
									income_taxes: 0,
									net_discontinued_operations_result: 0,
									net_income_after_taxes_disc_oper: -80981000,
									net_income_atributable: -80981000,
								},
								cash_flow: {
									funds_form_operations: 0,
									change_in_working_capital: 0,
									dividends_received_oper: 0,
									cash_flow_operating_act_cfo: 0,
									capex: 0,
									investments_divestments: 0,
									dividends_received_invest: 0,
									other_cash_flow_inesting_act: 0,
									cash_flow_investing_act_cfi: 0,
									net_changes_financial_debt: 0,
									capital_increase: 0,
									dividends: 0,
									share_buyback: 0,
									other_cash_flow_financing_act: 0,
									cash_flow_financing_act_cff: 0,
									total_movements_in_cash: 0,
								},
								solvency: {
									gross_financial_debt_to_ebitad_adj_x: 9.17,
									net_financial_debt_to_ebitad_adj_x: 9.11,
									ebitad_adj_interest_expenses: 1.36,
									gif_to_gross_financial_debt: 19,
									gif_to_net_financial_debt: 20,
									ebitad_adj_fixed_charge: 0.19,
								},
								liquidity: {
									net_worth_total_asset: 18,
									long_term_debt_net_worth: 116,
									c_asset_c_liabilities: 0.67,
									fixed_asset_net_worth: 379,
									gross_financial_debt_to_net_worth: 2.33,
								},
								profitability: {
									ebitda_adj_margin: 6,
									roa: -6,
									roe: 32,
									roce: -4,
								},
								activity: {
									avg_days_sale_out: 59.39,
									avg_days_inventory_out: 1.64,
									avg_days_payable_out: 111.07,
									avg_period_to_maturity_days: 50.05,
								}
							}
						],
						// Capital Structure
						debt_maturity_profile: 'Negative',
						market_risk: '7.5',
						counter_party_credit_bank: '7.5',
						capital_structure_assessment: '...',
						// Off-Balance Sheet
						off_balance_sheet_risk: 8,
						off_balance_sheet_assessment: 'El cliente no registra en sus estados financieros la deuda por arrendamiento puro',
						// Financial Flexibility and Liquidity
						access_to_additional_founding_or_capital: 'Average',
						need_for_additional_founding_or_capital: 'Very High',
						financial_flexibility_liquidity_score: '8.5',
						financial_flexibility_liquidity_score_assessment: '...',
						undrawn_debt: 'NO',
						
					}
				},
				standalone_rating: {
					form: {
						// Standalone Rating
						scale: 'Long Scale BBVA',
						long_rating_value: '8.3',
						total_rating_score: '8.75',
						ffss_year: '2022-06-30',
					}
				},
				overlays: {
					form: {
						// General
						adjustment_gre: 'NO',
						wl_level: '2',
						// WL Overlay
						// Input
						icr_rating: '8-3',
						icr_rating_score: 8.75,
						input_wl_level: 2,
						icr_rating_adjustment: '',
						icr_rating_comments: '',
						// Output
						overlay_rating: '8-3',
						overlay_rating_score: '8.75',
					}
				}
			}
		},
		{
			id: 2,
			fecha: '',
			bbvaid: '987456',
			numero: '000001352',
			nombre: 'Soriano Group, SA DE CV',
			folio: '000001352',
			tipo: 'Grupo',
			padre: 0,
			propietario: 'Soriano',
			rfc: 'HLQ090319YUW',
			fundacion: '2002',
			clinte_desde: '2010',
			pais_cabecera: 'Mexico',
			examen: false,
			rating: {

			}
		},
		
	]
}