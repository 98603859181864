<script>
import { mapGetters } from 'vuex';
export default {
	computed: {
		...mapGetters({
			evaluations: 'evaluations/evaluations'
		})
	},
	methods: {
		setObjetivesSCROM() {
			let vm = this
			vm.evaluations.forEach((element, index) => {
				window._SCORM.set(`cmi.objectives.${index}.id `, `evaluation-${element.type}-${element.id}-${element.activityId}`)
				window._SCORM.set(`cmi.objectives.${index}.score.max`, 100)
				window._SCORM.save()
			});
		}
	},
	mounted() {
		window._SCORM.init()
		window._SCORM.get('cmi.objectives._count')
	}
}
</script>
<template>
  <div id="app">
    <router-view :key="$route.fullPath"/>
	<vue-snotify></vue-snotify>
  </div>
</template>

<style lang="scss">
@import './assets/css/bootstrap.min.css';
@import './assets/css/style.scss';
@import './assets/css/font-awesome.min.css';
@import "~vue-snotify/styles/material";
#app {
  font-family: "BentonSansBBVA-Medium", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
//   text-align: center;
  color: #2c3e50;
  font-size: 1em;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
