import { saveUserEvaluation } from '../store/auth/actions'

const Datastore = require('nedb-promises')

let db = new Datastore({
	filename: 'progress.db', // provide a path to the database file 
	autoload: true, // automatically load the database
	timestampData: true // automatically add and manage the fields createdAt and updatedAt,
	
})
export default {
	async all() {
		return await db.find({})
	},
	async getAll(payload) {
		return await db.find({
			userId: payload,
		},{})
	},
	
	async findOne(payload) {
		return await db.findOne({
			userId: payload.userId,
			activityId: payload.activityId
		},{})
	},

	async save(payload) {
		let exist = await this.findOne({userId: payload.userId, activityId: payload.activityId})
		if(!exist) {
			return await db.insert(Object.assign({}, payload))
		}
		return db.update({ userId: payload.userId, activityId: payload.activityId }, payload)
	},

	async saveEvaluation(payload) {
		let exist = await this.findOne({userId: payload.userId, activityId: payload.activityId})
	}
}