import progress from '../../services/progress'

export const getUserProgress = async ({commit}, userId) => {
	let userPogress = await progress.getAll(userId)
	commit('setUserProgress', userPogress)
	return true
}

export const saveUserProgress = async ({commit, dispatch}, payload) => {
	try{
		let userPogress = await progress.save(payload)
		dispatch('getUserProgress', payload.userId)
		return Promise.resolve(userPogress)
	} catch(err) {
		return Promise.reject(err)
	}
}

