// import { saveUserEvaluation } from '../store/auth/actions'

const Datastore = require('nedb-promises')

let db = new Datastore({
	filename: 'arce.db', // provide a path to the database file 
	autoload: true, // automatically load the database
	timestampData: true // automatically add and manage the fields createdAt and updatedAt
})

export default {
	async save(payload) {
		return await db.insert(Object.assign({}, payload))
	},

	async findAll(params) {
		return await db.find(params);
	},
	// async findByUser(id) {
	// 	return await db.find({userId: id});
	// },

	async findOne(payload) {
		return await db.findOne(payload,{})
	},

	async update(payload) {
		return await db.update({_id: payload._id}, {...payload})
	}
}