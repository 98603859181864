import store from '../store'
export default ({to, form, router, next}) => {
	const vuex = store
	const user = JSON.parse(localStorage.getItem('auth')) || null
    if (!user) {
		console.log(to)
		sessionStorage.setItem('afterLogin', to.path)
        return  router.push({ name: 'register' });
	}
	sessionStorage.removeItem('afterLogin')
	vuex.commit('auth/setAuthUser', user)
    return next();
}
