import Vue from 'vue'
import createMutationsSharer from "vuex-shared-mutations";
import Vuex from 'vuex'
import auth from './auth'

import arceClients from './arce/clients'
import arceRatings from './arce/ratings'
import arceStatus from './arce/status'
import arceDetails from './arce/details'
import arceEeff from './arce/eeff'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
	auth,
	// ARCE
	arceClients,
	arceStatus,
	arceDetails,
	arceEeff,
	arceRatings,
  },
  plugins: [createMutationsSharer({ predicate: [] })]
})
