import arce from '../../../services/arce'
import wizardarce from '../../../services/wizardarce'

export const saveArce = async ({commit}, payload) => {
	try{
		let data = await wizardarce.save(payload)
	} catch (err) {
	}
}

export const getArce = async ({commit}, payload) => {
	try {
		let data = await wizardarce.findOne(payload)
		commit('setCurrentArce', data.arce)
		commit('setStepsArce', data.steps)
	} catch (error) {
		
	}
}