import Vue from 'vue'

export const setArce = (state, payload) => {
	Vue.set(state, 'arce', {...payload})
}

export const setChild = (state, payload) => {
	let children = state.arce.children || []
	let exist = children.find(ch => ch.id === payload.id)
	if(exist) {
		return
	}
	payload.arce = {}
	payload.arceFill = {}
	children.push({...payload})
	Vue.set(state.arce, 'children', children)
}

export const removeChild = (state, payload) => {
	let children = [...state.arce.children]
	
	let index = children.findIndex(ch => ch.id === payload.id)
	
	if(index < 0) {
		return 
	}
	children.splice(index, 1)
	Vue.set(state.arce, 'children', children)
}

export const setDataChild = (state, payload) => {
	let arce = state.arce
	let children = arce.children || []

	let currentChild = children.find(ch => ch.id == payload.client.id)
	if(currentChild) {
		Vue.set(currentChild.arce, payload.form, payload.data)
		Vue.set(currentChild.arceFill, payload.form, payload.fill)
	}
}

export const setShareholders = (state, payload) => {
	let client = state.arce.children.find(c => c.id == payload.clientId)
	Vue.set(client, 'shareholders', payload.shareholders)
}