export const showSearchClient = (state) => {
	return state.searchClient
}

export const arceClient = (state) => {
	return state.currentArce.client
}

export const currentArce = (state) => {
	return state.currentArce
}

export const showArceWizard = (state) => {
	return state.arceWizard
}

export const stepsArce = (state) => {
	return state.stepsArce
}

export const statusStep = (state) => (key) => {
	let step = state.stepsArce.find(s => s.key == key)
	return step.status
}

export const currentStep = (state) => {
	return state.stepsArce.find(s => s.status == 'current')
}

export const showHelp = (state) => state.help



