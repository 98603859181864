import Vue from 'vue'
export const setAuthUser = (state, user) => {
	localStorage.setItem('auth', JSON.stringify(user))
	Vue.set(state, 'user', Object.assign({}, user))
} 

export const removeUser = (state) => {
	localStorage.removeItem('auth')
	Vue.set(state, 'user', {})
	return Promise.resolve()
}

export const setUserProgress =  (state, payload) => {
	Vue.set(state, 'progress', payload)
}


export const setTest = (state, payload) => {
	Vue.set(state, 'isTest', payload)
}