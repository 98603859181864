import Vue from "vue"

export const setRating = (state, payload) => {
	let rating = state.ratings.find(r => r.id == payload)
    Vue.set(state, 'selected', rating)
}

export const add = (state, payload) => {
	let id = Date.now()
    let data = {...payload, ...{id, wizard_completed: false}}
    state.ratings.push(data)
    console.log(state.ratings)
    localStorage.setItem('ratings', JSON.stringify(state.ratings))
    Vue.set(state, 'selected', data)

}

export const setWizardCompleted = (state) => {
    Vue.set(state.selected,'wizard_completed', true)
    localStorage.setItem('ratings', JSON.stringify(state.ratings))
}

export const updateAnalysis = (state, payload) => {
    Vue.set(state.selected.analysis[payload.key],'form', payload.form)
    Vue.set(state.selected.analysis[payload.key],'data', payload.data)
    localStorage.setItem('ratings', JSON.stringify(state.ratings))
}

