<script>
import { mapGetters } from 'vuex'
export default {
	props: {
		max: {
			type: Number,
			default: 500
		},

		text : {
			type: String,
		}
	},

	computed: {
		counter() {
			return (this.max - this.text.length) > 0 ? (this.max - this.text.length) : 0
		},
		...mapGetters({
			showHelp: 'arceStatus/showHelp'
		})
	}
}
</script>
<template>
	<span v-if="showHelp" class="characters-available" :class="{'danger': counter < 10}">{{counter}} Caracteres Disponibles</span> 
</template>