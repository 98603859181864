import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import {mapGetters} from 'vuex'
import {SCORM} from 'pipwerks-scorm-api-wrapper'
import { EventBus } from './eventBus'

import VueQuillEditor from 'vue-quill-editor'
import SvgIcon from '@/components/SvgIcon'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(VueQuillEditor, /* { default global options } */)


var jquery = require('jquery')
var popper = require('popper.js')

window.$ = jquery
window._ = require('lodash')
window._SCORM = SCORM
window.axios = axios

var bootstap = require('bootstrap')

Vue.config.productionTip = false

const plugins = require.context('./plugins', true, /\.js$/i)

plugins.keys().map(key => {
	plugins(key)
})

Vue.mixin({
	components: {
		SvgIcon
	},
	data() {
		return {
			isElectron: process.env.IS_ELECTRON
		}
	},
	computed: {
		...mapGetters({
			getNextActivity: 'activities/next'
		})
	},
	methods: {
		eventBusEmit(name, payload) {
			EventBus.$emit(name, payload)
		},
		eventBusOn(name, callback) {
			EventBus.$on(name, callback)
		},
		eventBusOff(name, callback) {
			EventBus.$off(name, callback)
		},
		downloadExcel(excel) {
			// var data1 = [{a:1,b:10},{a:2,b:20}];
			// var data2 = [{a:100,b:10},{a:200,b:20}];
			// var opts = [{sheetid:'One',header:true},{sheetid:'Two',header:false}];
			var opts = excel.fields
			var res = alasql(`SELECT * INTO XLSX("${excel.name}.xlsx",?) FROM ?`,
							[opts,excel.data]);
		},
		goNext(currentId) {
			const activity = this.getNextActivity(currentId)
			const type = activity.type
			if(type === 'module') return this.setModule(activity)
			if(type === 'video') return this.$router.push({name: 'formation-index', params: {modal: 'video', activity: activity.activityId}})
			if(type === 'gamelearning') return this.$router.push({name: 'formation-activity', params: {module: activity.parent || 0,activity: activity.activityId}})
			if(type === 'questions') return this.$router.push({name: 'formation-questions', params: {module: activity.parent || 0,activity: activity.activityId}})
			return this.$router.push(activity.url)
		},
		JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
			//If JSONData is not an object then JSON.parse will parse the JSON string in an Object
			var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
		  
			var CSV = '';
			//Set Report title in first row or line
		  
			CSV += ReportTitle + '\r\n\n';
		  
			//This condition will generate the Label/Header
			if (ShowLabel) {
			  var row = "";
		  
			  //This loop will extract the label from 1st index of on array
			  for (var index in arrData[0]) {
		  
				//Now convert each value to string and comma-seprated
				row += index + ',';
			  }
		  
			  row = row.slice(0, -1);
		  
			  //append Label row with line break
			  CSV += row + '\r\n';
			}
		  
			//1st loop is to extract each row
			for (var i = 0; i < arrData.length; i++) {
			  var row = "";
		  
			  //2nd loop will extract each column and convert it in string comma-seprated
			  for (var index in arrData[i]) {
				row += '"' + arrData[i][index] + '",';
			  }
		  
			  row.slice(0, row.length - 1);
		  
			  //add a line break after each row
			  CSV += row + '\r\n';
			}
		  
			if (CSV == '') {
			  alert("Invalid data");
			  return;
			}
		  
			//Generate a file name
			var fileName = "MyReport_";
			//this will remove the blank-spaces from the title and replace it with an underscore
			fileName += ReportTitle.replace(/ /g, "_");
		  
			//Initialize file format you want csv or xls
			var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
		  
			// Now the little tricky part.
			// you can use either>> window.open(uri);
			// but this will not work in some browsers
			// or you will not get the correct file extension    
		  
			//this trick will generate a temp <a /> tag
			var link = document.createElement("a");
			link.href = uri;
		  
			//set the visibility hidden so it will not effect on your web-layout
			link.style = "visibility:hidden";
			link.download = fileName + ".csv";
		  
			//this part will append the anchor tag and remove it after automatic click
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
