import state from "../../auth/state"

export const getById = (state) => (id) => {
	let client = {...state.clients.find(client => client.id == id)}
	if(client) {
		client.parent = state.clients.find(c => c.id == client.parent) || null
	}
	return client
}

export const allClients = (state) => {
	return state.clients.filter(item => item.parent == 0)
}

export const searchClients = (state) => (query) => {
	if(!query) return []
	let p_query = query
	return state.clients.filter(client => {
		if(client.examen) {
			return client.code == p_query
		}
		query = query.toLowerCase()
		if(client.nombre.toLowerCase().includes(query)) return true
		if(client.alpha_code.toLowerCase().includes(query)) return true
		if(client.bbvaid.toLowerCase().includes(query)) return true
	}).splice(0, 5)
}

export const groupStructure = (state) => (id) => {
	let client = {...state.clients.find(c => c.id == id)}

	if(client.parent) {
		let group = {...state.clients.find(c => c.id == client.parent)}
		group.children = [...state.clients.filter(c => c.parent == group.id)]
		return group
	}

	client.children = state.clients.filter(c => c.parent == client.id)

	return client
}

export const getSubsidiaries = (state) => (id) => {
	return state.clients.filter(c => c.parent == id)
}