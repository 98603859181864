export const userId = (state) => {
	return state.user._id
}
export const userName = (state) => {
	return state.user.name
}
export const userCode = (state) => {
	return state.user.code
}
export const userEmail = (state) => {
	return state.user.email
}
export const firstLogin = (state) => {
	return state.user.first_login || false
}

export const activityCompleted = (state) => (activityId) => {
	if(state.isTest) {
		return true
	}
	if(!activityId) return true
	return state.progress.some(a => (a.activityId === activityId && a.completed))
}

export const activityViewed = (state) => (activityId) => {
	if(state.isTest) {
		return true
	}
	if(!activityId) return false
	let activity = state.progress.some(a => (a.activityId === activityId && a.viewed))
	return activity
}

export const evaluationTries = (state) => (activityId) => {
	if(!activityId) return 0
	let activity = state.progress.find(a => a.activityId == activityId)
	if(!activity) return 0
	return activity.evaluationTries || 0
}

export const canTakeEvaluation = (state) => (activityId) => {
	if(state.isTest) {
		return true
	}
	if(!activityId) return 0
	let activity = state.progress.find(a => a.activityId == activityId)
	if(!activity) return false
	return true
}

export const progressById = (state) => (activityId) => {
	if(!activityId) return false
	let activity = state.progress.find(a => a.activityId == activityId) 
	return activity
}

export const fakeData = (state) => {
	return state.fakeData
}

export const unlocks = (state) => {
	return state.user.unlocks || []
}

export const checkUnlocks = (state) => (activityId) => {
	let unlocks = state.user.unlocks || []
	return unlocks.includes(activityId)
}