import Vue from 'vue'
import { stat } from 'fs'

export const setHelp = (state, payload) => {
	state.help = payload
}
export const setArceClient = (state, payload) => {
	Vue.set(state.currentArce, 'client', payload)
}

export const unsetArceClient = (state) => {
	Vue.set(state.currentArce, 'client', null)
}

export const showSearchClient = (state) => {
	state.searchClient = true
}

export const hideSearchClient = (state) => {
	state.searchClient = false
}

export const showArceWizard = (state) => {
	debugger
	state.arceWizard = true
}
export const hideArceWizard = (state) => {
	state.arceWizard = false
}

export const initArceWizard = (state, payload) => {
	let arceWizard = state.currentArce
	let steps = state.stepsArce 
	steps.forEach(element => {
		let newSteps = payload.steps.find(s => s.key == element.key)
		if(newSteps) {
			element.status = newSteps.status
		}
	});
	// Vue.set(state, 'currentArce', Object.assign({...arceWizard}, {...payload.data}))
	Vue.set(state, 'currentArce', Object.assign({...arceWizard}, {...payload.data}))
}

export const setCurrentArce = (state, payload) => {
	Vue.set(state, 'currentArce', {...payload})
}

export const resetCurrentArce = (state, payload) => {
	Vue.set(state, 'currentArce', {
		start: null,
		client: null,
		groupStructure: null,
		type: null,
		information: {
			financialSponsor: false,
			ews: false,
			checklist: false,
			eeff: false,
			code: false,
		},
		sector: null,
	})
}

export const setStepsArce = (state, payload) => {
	Vue.set(state, 'stepsArce', [...payload])
}

export const resetStepsArce = (state, payload) => {
	Vue.set(state, 'stepsArce', [
		{
			sort: 1,
			key: 'start',
			status: 'pedding'
		},
		{
			sort: 2,
			key: 'groupStructure',
			status: 'pedding'
		},
		{
			sort: 3,
			key: 'type',
			status: 'pedding'
		},
		{
			sort: 4,
			key: 'information',
			status: 'pedding'
		},
		{
			sort: 5,
			key: 'sector',
			status: 'pedding'
		},
		{
			sort: 6,
			key: 'finish',
			status: 'pedding'
		},
	])
}

export const markPassedStep = (state, payload) => {
	let current = state.stepsArce.find(s => s.key == payload)
	current.status = 'passed'

	let next = state.stepsArce.find(c => c.sort == (current.sort + 1))

	if(next) {
		next.status = 'current'
	}
}

export const setInformation = (state, payload) => {
	Vue.set(state.currentArce, 'information',  {...payload})
}

export const setSector = (state, payload) => {
	Vue.set(state.currentArce, 'sector',  payload)
}

export const setOperationType = (state, payload) => {
	Vue.set(state.currentArce, 'operationType',  payload)
}

export const resetWizardArce = (state, payload) => {
	Vue.set(state, 'currentArce', 
	{
		start: null,
		client: null,
		groupStructure: null,
		type: null,
		information: {
			financialSponsor: false,
			ews: false,
			checklist: false,
			eeff: false,
			code: false,
		},
		sector: null,
	})

	Vue.set(state, 'stepsArce', 
	[
		{
			sort: 1,
			key: 'start',
			status: 'pedding'
		},
		{
			sort: 2,
			key: 'groupStructure',
			status: 'pedding'
		},
		{
			sort: 3,
			key: 'type',
			status: 'pedding'
		},
		{
			sort: 4,
			key: 'information',
			status: 'pedding'
		},
		{
			sort: 5,
			key: 'sector',
			status: 'pedding'
		},
		{
			sort: 6,
			key: 'finish',
			status: 'pedding'
		},
	])
}