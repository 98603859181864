export default {
	arce: null,
	caseFile: [
		{
			client_id: 5,
			base_path: './expediente/don_ramitos',
			name: 'Don Ramitos',
			files: { 
				buros: [],
				eeff: [],
				general: [
					{
						name: '1.Información_General.1 Don Ramitos.pdf​',
						src: '/1.CasoDonRamitos_InformacionGeneral.pdf',
						type: 'pdf'
					},
					{
						name: '5.Razones Financieras Sectoriales.A.pdf',
						src: '/5.Razones_Financieras_Sectoriales.A.pdf',
						type: 'pdf'
					},
					{
						name: '6.Clasificación Sectorial México (sept-20).xlsx',
						src: '/6.Clasificacion_Sectorial_Mexico_sept-20.xlsx',
						type: 'pdf'
					},
					{
						name: '9.Fibras y Resinas 2020.A.pdf',
						src: '/9.Fibras_y_Resinas_2020.A.pdf',
						type: 'pdf'
					},
					{
						name: '11.Práctica_Don Ramis_Información complementaria.A.pdf',
						src: '/11.Practica_Don_Ramis_Informacion_complementaria.A.pdf',
						type: 'pdf'
					},
					{
						name: '12.Practica_Don_Ramis_Sintesis_Creditlens_​Arce_Corporates​.pdf',
						src: '/12.Practica_Don_Ramis_Sintesis_Creditlens_Arce_Corporates.pdf',
						type: 'pdf'
					},
					{
						name: '13.Práctica_Filiales_Sintesis_RI.A.pdf',
						src: '/13.Practica_Filiales_Sintesis_RI.A.pdf',
						type: 'pdf'
					},
					{
						name: '15.Reporte CO Julián José Pérez Abuin.A.pdf',
						src: '/15.Reporte_CO_Julian_Jose_Perez_Abuin.A.pdf',
						type: 'pdf'
					},
					{
						name: '16.Reporte CO Agrotecamac SA de CV.A.pdf',
						src: '/16.Reporte_CO_Agrotecamac_SA_de_CV.A.pdf',
						type: 'pdf'
					},
					{
						name: '17.Reporte CO Distribuidora Don Ramis SA d CV.pdf',
						src: '/17.Reporte_CO_Distribuidora_Don_Ramis_SA_d_CV.pdf',
						type: 'pdf'
					},
					{
						name: '18.Reporte CO El Juan Drio SA de CV.A.pdf',
						src: '/18.Reporte_CO_El_Juan_Drio_SA_de_CV.A.pdf',
						type: 'pdf'
					},
					{
						name: '19.Reporte CO Inmobiliaria San Juan Lugo SA de CV.A.pdf',
						src: '/19.Reporte_CO_Inmobiliaria_San_Juan_Lugo_SA_de_CV.A.pdf',
						type: 'pdf'
					},
					{
						name: '20.Reporte CO Jimena Abuin Salgado.A.pdf',
						src: '/20.Reporte_CO_Jimena_Abuin_Salgado.A.pdf',
						type: 'pdf'
					},
					{
						name: '22.Reporte CO Servicio Sujuxi SA de CV.A.pdf',
						src: '/22.Reporte_CO_Servicio_Sujuxi_SA_de_CV.A.pdf',
						type: 'pdf'
					},
					{
						name: '23.Reporte CO Transportes Ayan SA de CV.A.pdf',
						src: '/23.Reporte_CO_Transportes_Ayan_SA_de_CV.A.pdf',
						type: 'pdf'
					},
					{
						name: '24.Reporte de Crédito Jimena Abuin Salgado.A.pdf',
						src: '/24.Reporte_de_Crédito_Jimena_Abuin_Salgado.A.pdf',
						type: 'pdf'
					},
					{
						name: '25.Reporte de Crédito Julian José Pérez Abuin.A.pdf',
						src: '/25.Reporte_de_Crédito_Julian_José_Pérez_Abuin.A.pdf',
						type: 'pdf'
					},
					{
						name: '26.Socidad_2017082466.A.pdf',
						src: '/26.Socidad_2017082466.A.pdf',
						type: 'pdf'
					},
					{
						name: '27.Sociedad_2017082487.A.pdf',
						src: '/27.Sociedad_2017082487.A.pdf',
						type: 'pdf'
					},
					{
						name: '28.Sociedad_2017082487_1.A.pdf',
						src: '/28.Sociedad_2017082487_1.A.pdf',
						type: 'pdf'
					},
					
				],
				ra: []
			}
		},
		{
			client_id: 11,
			base_path: './expediente/Examen_1',
			name: 'Comercializadora del Sureste S.A de C.V.',
			files: {
				buros: [
					{
						name: '1.Embotelladora del Sureste SA DE CV.pdf',
						src: '/Buros/1.Embotelladora_del_Sureste_SA_DE_CV.pdf',
						type: 'pdf'
					},
					{
						name: '2.Sureste Industrial SA DE CV.pdf',
						src: '/Buros/2.Sureste_Industrial_SA_DE_CV.pdf',
						type: 'pdf'
					},
					{
						name: '3.Sureste Motriz SA DE CV.pdf',
						src: '/Buros/3.Sureste_Motriz_SA_DE_CV.pdf',
						type: 'pdf'
					},
					{
						name: '4.Grupo Financiero del Sureste SA DE CV SOFOM ENR.pdf',
						src: '/Buros/4.Grupo_Financiero_del_Sureste_SA_DE_CV_SOFOM_ENR.pdf',
						type: 'pdf'
					},
					{
						name: '5. Fomento Corporativo del Sureste.pdf',
						src: '/Buros/5._Fomento_Corporativo_del_Sureste.pdf',
						type: 'pdf'
					},
				],
				eeff: [
					{
						name: 'Embotelladora del Sureste 2013  Y 2014.pdf',
						src: '/EEFF_Fuente/Embotelladora_del_Sureste_2013__Y_2014.pdf',
						type: 'pdf'
					},
					{
						name: 'Embotelladora del Sureste 2014.pdf',
						src: '/EEFF_Fuente/Embotelladora_del_Sureste_2014.pdf',
						type: 'pdf'
					},
					{
						name: 'Fomento Corporativo del Sureste 2013 y 2014.pdf',
						src: '/EEFF_Fuente/Fomento_Corporativo_del_Sureste_2013_y_2014.pdf',
						type: 'pdf'
					},
					{
						name: 'Fomento Corporativo del Sureste 2014.pdf',
						src: '/EEFF_Fuente/Fomento_Corporativo_del_Sureste_2014.pdf',
						type: 'pdf'
					},
					{
						name: 'Grupo Financiero del Sureste 2013 y 2014.pdf',
						src: '/EEFF_Fuente/Grupo_Financiero_del_Sureste_2013_y_2014.pdf',
						type: 'pdf'
					},
					{
						name: 'Sureste Industrial 2013 y 2014.pdf',
						src: '/EEFF_Fuente/Sureste_Industrial_2013_y_2014.pdf',
						type: 'pdf'
					},
					{
						name: 'Sureste Industrial2014.pdf',
						src: '/EEFF_Fuente/Sureste_Industrial2014.pdf',
						type: 'pdf'
					},
					{
						name: 'Sureste Motriz 2014.pdf',
						src: '/EEFF_Fuente/Sureste_Motriz_2014.pdf',
						type: 'pdf'
					},
					{
						name: 'Sureste Motriz y sub 2013 y 2014.pdf',
						src: '/EEFF_Fuente/Sureste_Motriz_y_sub_2013_y_2014.pdf',
						type: 'pdf'
					},
				],
				general: [
					{
						name: 'Analisis Sectorial Act.pdf',
						src: '/Informacion_general/Analisis_Sectorial_Act..pdf',
						type: 'pdf'
					},
					{
						name: 'Caso_Comercializadora del Sureste. Act.pdf',
						src: '/Informacion_general/Caso_Comercializadora_del_Sureste._Act..pdf',
						type: 'pdf'
					},
					{
						name: 'Comentarios Situacion Economica Financiera.pdf',
						src: '/Informacion_general/Comentarios_Situacion_Economica_Financiera.pdf',
						type: 'pdf'
					},
					{
						name: 'Cuadro de Posiciones.xlsx',
						src: '/Informacion_general/Cuadro_de_Posiciones.xlsx',
						type: 'excel'
					},
					{
						name: 'Proyecciones Financieras.pdf',
						src: '/Informacion_general/Proyecciones_Financieras.pdf',
						type: 'pdf'
					},
				],
				ra: [
					{
						name: 'Embotelladora del Sureste RA 2013.pdf',
						src: '/RA/Embotelladora_del_Sureste_RA_2013.pdf',
						type: 'pdf'
					},
					{
						name: 'Embotelladora del Sureste RA 2014.pdf',
						src: '/RA/Embotelladora_del_Sureste_RA_2014.pdf',
						type: 'pdf'
					},
					{
						name: 'Fomento Corporativo del Sureste RA 2013.pdf',
						src: '/RA/Fomento_Corporativo_del_Sureste_RA_2013.pdf',
						type: 'pdf'
					},
					{
						name: 'Fomento Corporativo del Sureste RA 2014.pdf',
						src: '/RA/Fomento_Corporativo_del_Sureste_RA_2014.pdf',
						type: 'pdf'
					},
					{
						name: 'Sureste Industrial RA 2013.pdf',
						src: '/RA/Sureste_Industrial_RA_2013.pdf',
						type: 'pdf'
					},
					{
						name: 'Sureste Industrial RA 2014.pdf',
						src: '/RA/Sureste_Industrial_RA_2014.pdf',
						type: 'pdf'
					},
					{
						name: 'Sureste Motriz RA 2013.pdf',
						src: '/RA/Sureste_Motriz_RA_2013.pdf',
						type: 'pdf'
					},
					{
						name: 'Sureste Motriz RA 2014.pdf',
						src: '/RA/Sureste_Motriz_RA_2014.pdf',
						type: 'pdf'
					},
				]
			}
		},
		{
			client_id: 16,
			base_path: './expediente/Examen_2',
			name: 'Grupo Kraken SA de CV',
			files: {
				buros: [
					{
						name: 'CONSMEXICA SA de CV BURO DE CREDITO.pdf',
						src: '/Buros/CONSMEXICA_SA_de_CV_BURO_DE_CREDITO.pdf',
						type: 'pdf'
					},
					{
						name: 'HUASTECA SAB de CV BURO DE CREDITO.pdf',
						src: '/Buros/HUASTECA_SAB_de_CV_BURO_DE_CREDITO_.pdf',
						type: 'pdf'
					},
					{
						name: 'KRAKEN SA de CV BURO DE CREDITO.pdf',
						src: '/Buros/KRAKEN_SA_de_CV_BURO_DE_CREDITO.pdf',
						type: 'pdf'
					},
					{
						name: 'MEXICOS SAB DE CV BURO DE CREDITO.pdf',
						src: '/Buros/MEXICOS_SAB_DE_CV_BURO_DE_CREDITO.pdf',
						type: 'pdf'
					},
				],
				eeff: [
					{
						name: 'CONSMEXICA SINTESIS FINANCIERA Creditlens ARCE Corporate.pdf',
						src: '/EEFF_Fuente/CONSMEXICA_SINTESIS_FINANCIERA_Creditlens_ARCE_Corporate.pdf',
						type: 'pdf'
					},
					{
						name: 'CONSMEXICA Y SUBS 2021.pdf',
						src: '/EEFF_Fuente/CONSMEXICA_Y_SUBS_2021.pdf',
						type: 'pdf'
					},
					{
						name: 'HUASTECA SINTESIS FINANCIERA Creditlens ARCE Corporate.pdf',
						src: '/EEFF_Fuente/HUASTECA_SINTESIS_FINANCIERA_Creditlens_ARCE_Corporate.pdf',
						type: 'pdf'
					},
					{
						name: 'HUASTECA_Y_SUBS_2021.pdf',
						src: '/EEFF_Fuente/HUASTECA_Y_SUBS_2021.pdf',
						type: 'pdf'
					},
					{
						name: 'KRAKEN EEFF SINTESIS FINANCIERA Creditlens ARCE Corporate.pdf',
						src: '/EEFF_Fuente/KRAKEN_EEFF_SINTESIS_FINANCIERA_Creditlens_ARCE_Corporate.pdf',
						type: 'pdf'
					},
					{
						name: 'KRAKEN Y SUBS 2021.pdf',
						src: '/EEFF_Fuente/KRAKEN_Y_SUBS_2021.pdf',
						type: 'pdf'
					},
					{
						name: 'MEXICOS SINTESIS FINANCIERA Creditlens ARCE Corporate.pdf',
						src: '/EEFF_Fuente/MEXICOS_SINTESIS_FINANCIERA_Creditlens_ARCE_Corporate.pdf',
						type: 'pdf'
					},
					{
						name: 'MEXICOS Y SUBS 2021.pdf',
						src: '/EEFF_Fuente/MEXICOS_Y_SUBS_2021.pdf',
						type: 'pdf'
					},
				],
				general: [
					{
						name: 'Caso Grupo Kraken.pdf',
						src: '/Informacion_general/Caso_Grupo_Kraken.pdf',
						type: 'pdf'
					},
					{
						name: 'COMENTARIOS KRAKEN.pdf',
						src: '/Informacion_general/COMENTARIOS_KRAKEN.pdf',
						type: 'pdf'
					},
					{
						name: 'Cuadro de Pasivos Examen 2.pdf',
						src: '/Informacion_general/Cuadro_de_Pasivos_Examen_2.pdf',
						type: 'pdf'
					},
					{
						name: 'CUADRO DE POSICIONES KRAKEN.xlsx',
						src: '/Informacion_general/CUADRO_DE_POSICIONES_KRAKEN.xlsx',
						type: 'excel'
					},
					{
						name: 'INFORMACION DEL SECTOR.pdf',
						src: '/Informacion_general/INFORMACION_DELSECTOR.pdf',
						type: 'pdf'
					},
					{
						name: 'PROYECCIONES KRAKEN.pdf',
						src: '/Informacion_general/PROYECCIONES_KRAKEN.pdf',
						type: 'pdf'
					},
				],
				ra: [
					{
						name: 'CONSMEXICA 2020 Creditlens ARCE Corporate.pdf',
						src: '/RA/CONSMEXICA_2020_Creditlens_ARCE_Corporate.pdf',
						type: 'pdf'
					},
					{
						name: 'CONSMEXICA 2021 Creditlens ARCE Corporate.pdf',
						src: '/RA/CONSMEXICA_2021_Creditlens_ARCE_Corporate.pdf',
						type: 'pdf'
					},
					{
						name: 'HUASTECA 2020 Creditlens ARCE Corporate.pdf',
						src: '/RA/HUASTECA_2020_Creditlens_ARCE_Corporate.pdf',
						type: 'pdf'
					},
					{
						name: 'HUASTECA 2021 Creditlens ARCE Corporate.pdf',
						src: '/RA/HUASTECA_2021_Creditlens_ARCE_Corporate.pdf',
						type: 'pdf'
					},
					{
						name: 'KRAKEN 2020 Creditlens ARCE Corporate.pdf',
						src: '/RA/KRAKEN_2020_Creditlens_ARCE_Corporate.pdf',
						type: 'pdf'
					},
					{
						name: 'KRAKEN 2021 Creditlens ARCE Corporate.pdf',
						src: '/RA/KRAKEN_2021_Creditlens_ARCE_Corporate.pdf',
						type: 'pdf'
					},
					{
						name: 'MEXICOS 2020 Creditlens ARCE Corporate.pdf',
						src: '/RA/MEXICOS_2020_Creditlens_ARCE_Corporate.pdf',
						type: 'pdf'
					},
					{
						name: 'MEXICOS 2021 Creditlens ARCE Corporate.pdf',
						src: '/RA/MEXICOS_2021_Creditlens_ARCE_Corporate.pdf',
						type: 'pdf'
					},
				]
			}
		},
		{
			client_id: 19,
			base_path: './expediente/Examen_3',
			name: 'México de Metales Internacionales SAB',
			files: {
				buros: [
					{
						name: '1. Metales Internacionales SAB.pdf',
						src: '/Buros/1._Metales_Internacionales_SAB.pdf',
						type: 'pdf'
					},
					{
						name: '2.Nations Mining Corporation.pdf',
						src: '/Buros/2.Nations_Mining_Corporation.pdf',
						type: 'pdf'
					},
					{
						name: '3.Proyectos y Desarrollos Nacionales.pdf',
						src: '/Buros/3.Proyectos_y_Desarrollos_Nacionales.pdf',
						type: 'pdf'
					},
					{
						name: '4.Infraestructura y Transportes Nacionales.pdf',
						src: '/Buros/4.Infraestructura_y_Transportes_Nacionales.pdf',
						type: 'pdf'
					},
				],
				eeff: [
					{
						name: 'Infraestructura y Transportes Nacionales.pdf',
						src: '/EEFF_Fuente/Infraestructura_y_Transportes_Nacionales.pdf',
						type: 'pdf'
					},
					{
						name: 'Metales-Internacionales.pdf',
						src: '/EEFF_Fuente/Metales-Internacionales.pdf',
						type: 'pdf'
					},
					{
						name: 'Nations Mining Corporation.pdf',
						src: '/EEFF_Fuente/Nations_Mining_Corporation.pdf',
						type: 'pdf'
					},
					{
						name: 'Proyectos y Desarrollos Nacionales.pdf',
						src: '/EEFF_Fuente/Proyectos_y_Desarrollos_Nacionales.pdf',
						type: 'pdf'
					},
				],
				general: [
					{
						name: 'analisis sectorial.pdf',
						src: '/Informacion_general/analisis_sectorial.pdf',
						type: 'pdf'
					},
					{
						name: 'Caso_Metales Internacionales.pdf',
						src: '/Informacion_general/Caso_Metales_Internacionales.pdf',
						type: 'pdf'
					},
					{
						name: 'Comentarios Situacion Economica Financiera.pdf',
						src: '/Informacion_general/Comentarios_Situacion_Economica_Financiera.pdf',
						type: 'pdf'
					},
					{
						name: 'Cuadro de Pasivos Examen 3.pdf',
						src: '/Informacion_general/Cuadro_de_Pasivos_Examen_3.pdf',
						type: 'pdf'
					},
					{
						name: 'Cuadro De Posiciones.xlsx',
						src: '/Informacion_general/Cuadro_De_Posiciones.xlsx',
						type: 'excel'
					},
					{
						name: 'Posicion Competitiva.pdf',
						src: '/Informacion_general/Posicion_Competitiva.pdf',
						type: 'pdf'
					},
					{
						name: 'Proyecciones financieras.pdf',
						src: '/Informacion_general/Proyecciones_financieras.pdf',
						type: 'pdf'
					},
				],
				ra: [
					{
						name: 'ITN EEFF 2013 RA.pdf',
						src: '/RA/ITN_EEFF_2013_RA.pdf',
						type: 'pdf'
					},
					{
						name: 'ITN RATING 2012.pdf',
						src: '/RA/ITN_RATING_2012.pdf',
						type: 'pdf'
					},
					{
						name: 'ITN RATING 2013.pdf',
						src: '/RA/ITN_RATING_2013.pdf',
						type: 'pdf'
					},
					{
						name: 'Metales Internacionales 2012 RA.pdf',
						src: '/RA/Metales_Internacionales_2012_RA.pdf',
						type: 'pdf'
					},
					{
						name: 'Metales Internacionales 2013 RA.pdf',
						src: '/RA/Metales_Internacionales_2013_RA.pdf',
						type: 'pdf'
					},
					{
						name: 'Metales Internacionales_final.pdf',
						src: '/RA/Metales_Internacionales_final.pdf',
						type: 'pdf'
					},
					{
						name: 'NMC EEFF 2013 RA.pdf',
						src: '/RA/NMC_EEFF_2013_RA.pdf',
						type: 'pdf'
					},
					{
						name: 'NMC RATING 2012.pdf',
						src: '/RA/NMC_RATING_2012.pdf',
						type: 'pdf'
					},
					{
						name: 'NMC RATING 2013.pdf',
						src: '/RA/NMC_RATING_2013.pdf',
						type: 'pdf'
					},
					{
						name: 'PDN EEFF 2013 RA.pdf',
						src: '/RA/PDN_EEFF_2013_RA.pdf',
						type: 'pdf'
					},
					{
						name: 'PDN_SA_2012.pdf',
						src: '/RA/PDN_SA_2012.pdf',
						type: 'pdf'
					},
					{
						name: 'PDN_SA_20131.pdf',
						src: '/RA/PDN_SA_20131.pdf',
						type: 'pdf'
					},
				]
			}
		},
		{
			client_id: 1,
			base_path: './expediente/soriano',
			name: 'Soriano Group',
			files: {
				buros: [
					{
						name: 'Buró de Crédito_Alberto Rementería PF',
						type: 'pdf',
						src: '/Buro_de_Credito_Alberto_Rementeria_PF.pdf'
					},
					{
						name: 'Buró de Crédito_Alberto Rementería PM',
						type: 'pdf',
						src: '/Buro_de_Credito_Alberto_Rementeria_PM.pdf'
					},
					{
						name: 'Buró de Crédito_Sergio Ayala PF',
						type: 'pdf',
						src: '/Buro_de_Credito_Sergio_Ayala_PF.pdf'
					},
					{
						name: 'Buró de Crédito_Sergio Ayala PM',
						type: 'pdf',
						src: '/Buro_de_Credito_Sergio_Ayala_PM.pdf'
					},
				],
				eeff: [
					{
						name: 'EEFF 2020 Soriano',
						type: 'pdf',
						src: '/EEFF_2020_Soriano.pdf'
					},
					{
						name: 'EEFF Cambio a 2018 y 2019',
						type: 'pdf',
						src: '/EEFF_Cambio_a_2018_y_2019.pdf'
					},
					{
						name: 'Identificación Apoderado',
						type: 'pdf',
						src: '/Identificacion_Apoderado.pdf'
					},
				],
				general: [
					{
						name: 'Bastanteo',
						type: 'pdf',
						src: '/Bastanteo.pdf'
					},
					
					
					{
						name: 'Identificación Aval',
						type: 'pdf',
						src: '/Identificacion_Aval.pdf'
					},
					{
						name: 'Relaciones Patrimoniales',
						type: 'pdf',
						src: '/Relaciones_Patrimoniales.pdf'
					},
					{
						name: 'Reporte vista fotográfico',
						type: 'pdf',
						src: '/Reporte_vista_fotografico.pdf'
					},
					{
						name: 'Riesgos globlales',
						type: 'pdf',
						src: '/Riesgos_globlales.pdf'
					},
					{
						name: 'Verificación de Propiedad SA',
						type: 'pdf',
						src: '/Verificacion_de_Propiedad_SA.pdf'
					},
					{
						name: 'Verificación de Propiedad',
						type: 'pdf',
						src: '/Verificacion_de_Propiedad.pdf'
					},
					{
						name: 'Verificación de Sociedad',
						type: 'pdf',
						src: '/Verificacion_de_Sociedad.pdf'
					},
				],
				ra: []
			}
		}
	]
}