export default {
	searchClient: false,
	arceWizard: false,
	// passed current pedding
	stepsArce:  [
		{
			sort: 1,
			key: 'start',
			status: 'passed'
		},
		{
			sort: 2,
			key: 'model',
			status: 'pedding'
		},
		{
			sort: 3,
			key: 'type',
			status: 'pedding'
		},
		{
			sort: 4,
			key: 'lading',
			status: 'pedding'
		},
		{
			sort: 5,
			key: 'financial',
			status: 'pedding'
		},
		{
			sort: 6,
			key: 'finish',
			status: 'pedding'
		},
	],
		
	currentArce: {
		start: null,
		client: null,
		groupStructure: null,
		type: null,
		information: {
			financialSponsor: false,
			ews: false,
			checklist: false,
			eeff: false,
			code: false,
		},
		sector: null,
		operationType: null,
	},
	help: false,
	allArce: [],
	
}